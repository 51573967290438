// Do not edit.
import { ShaderStore } from "../../Engines/shaderStore";

const name = "defaultVertexDeclaration";
const shader = `uniform mat4 viewProjection;uniform mat4 view;#ifdef DIFFUSE
uniform mat4 diffuseMatrix;uniform vec2 vDiffuseInfos;#endif
#ifdef AMBIENT
uniform mat4 ambientMatrix;uniform vec2 vAmbientInfos;#endif
#ifdef OPACITY
uniform mat4 opacityMatrix;uniform vec2 vOpacityInfos;#endif
#ifdef EMISSIVE
uniform vec2 vEmissiveInfos;uniform mat4 emissiveMatrix;#endif
#ifdef LIGHTMAP
uniform vec2 vLightmapInfos;uniform mat4 lightmapMatrix;#endif
#if defined(SPECULAR) && defined(SPECULARTERM)
uniform vec2 vSpecularInfos;uniform mat4 specularMatrix;#endif
#ifdef BUMP
uniform vec3 vBumpInfos;uniform mat4 bumpMatrix;#endif
#ifdef REFLECTION
uniform mat4 reflectionMatrix;#endif
#ifdef POINTSIZE
uniform float pointSize;#endif
#ifdef DETAIL
uniform vec4 vDetailInfos;uniform mat4 detailMatrix;#endif
#define ADDITIONAL_VERTEX_DECLARATION
`;
// Sideeffect
ShaderStore.IncludesShadersStore[name] = shader;
/** @hidden */
export const defaultVertexDeclaration = { name, shader };
