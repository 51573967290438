// Do not edit.
import { ShaderStore } from "../../Engines/shaderStore";

const name = "clipPlaneVertexDeclaration2";
const shader = `#ifdef CLIPPLANE
uniform vec4 vClipPlane;out float fClipDistance;#endif
#ifdef CLIPPLANE2
uniform vec4 vClipPlane2;out float fClipDistance2;#endif
#ifdef CLIPPLANE3
uniform vec4 vClipPlane3;out float fClipDistance3;#endif
#ifdef CLIPPLANE4
uniform vec4 vClipPlane4;out float fClipDistance4;#endif
#ifdef CLIPPLANE5
uniform vec4 vClipPlane5;out float fClipDistance5;#endif
#ifdef CLIPPLANE6
uniform vec4 vClipPlane6;out float fClipDistance6;#endif
`;
// Sideeffect
ShaderStore.IncludesShadersStore[name] = shader;
/** @hidden */
export const clipPlaneVertexDeclaration2 = { name, shader };
